/* Explosion effect */
.poop-explosion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* Full viewport height */
    pointer-events: none;
    /* Prevent interaction */
    overflow: hidden;
}

.poop-explosion .poop {
    position: absolute;
    /* Positioned absolutely within the explosion container */
    font-size: 2.5rem;
    animation: poop-fall 3s linear forwards;
    /* Falling effect */
    pointer-events: none;
}

/* Poop falls from initial top position (set in JS) to the bottom of the screen */
@keyframes poop-fall {
    0% {
        transform: translateY(0);
        /* Start from initial top position */
        opacity: 1;
    }

    100% {
        transform: translateY(100vh);
        /* Fall to the bottom of the viewport */
        opacity: 0;
    }
}
.hero-container {
    text-align: center;
    padding: 50px;
}

.hero-container h1 {
    font-size: 6rem;
    /* Increase font size for the main heading */
    color: #ff5733;
}

.hero-container p {
    font-size: 2rem;
    /* Increase font size for subheading */
    margin-bottom: 30px;
}

.poop-button {
    font-size: 2rem;
    /* Larger font for button */
    padding: 20px 40px;
    background-color: #ff6600;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 15px;
}

.poop-button:hover {
    background-color: #cc5200;
}

.video-container {
    margin: 20px auto;
    width: 80%;
}

.poop-explosion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
}

/* Responsive breakpoints for smaller screens */
@media (max-width: 768px) {
    .hero-container h1 {
            font-size: 3.5rem;
        }
    h1 {
        font-size: 3.5rem;
        padding: 5px;
    }

    .video-wrapper {
        max-width: 100%;
        padding-bottom: 56.25%;
    }

    .poop-button {
        font-size: 1.2rem;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .hero-container h1 {
            font-size: 2.5rem;
        }
    h1 {
        font-size: 2.5rem;
        padding: 5px;
    }

    .video-wrapper {
        max-width: 100%;
        padding-bottom: 56.25%;
        /* Keep the aspect ratio */
    }

    .poop-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}