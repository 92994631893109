/* src/components/Features.css */
.features-container {
    text-align: center;
    color:#998b8b;
    font-size: 2rem;
}

.features-container ul {
    list-style: none;
    padding: 0;
}

.features-container ul li {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #3e3e3e;
}