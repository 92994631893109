/* Wrapper that maintains the aspect ratio (16:9) */
.video-wrapper {
    position: relative;
    /* Aspect ratio (9 / 16 * 100) */
    height: 360px;
    /* This works with padding-bottom to keep the aspect ratio */
    overflow: hidden;
    width: 100%;
    max-width: 640px;
    /* Ensure the width doesn't get too large */
    margin: 0 auto;
    /* Center the video */
}

.youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Force the iframe to take 100% of the wrapper's width */
    height: 100%;
    /* Force the iframe to take 100% of the wrapper's height */
    border: 0;
    /* Remove any borders */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .video-wrapper {
        max-width: 100%;
        /* Allow the video to fill the width of the screen */
    }
}

@media (max-width: 480px) {
    .video-wrapper {
        max-width: 100%;
        /* Allow the video to fill the width of the screen */
    }
}