/* src/components/Header.css */
.header {
    background-color: #ffcc99;
    padding: 10px 0;
    text-align: center;
}

.header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.header ul li {
    display: inline;
    margin: 0 15px;
}

.header ul li a {
    text-decoration: none;
    color: #3e3e3e;
    font-weight: bold;
}
img {
    width: 40px;
}
.header{
    flex-shrink: 0;
}