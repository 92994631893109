html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
.poop-emoji{
position: absolute;
  /* Positioned absolutely within the explosion container */
  font-size: 2.5rem;
  animation: poop-fall 3s linear forwards;
  /* Falling effect */
  pointer-events: none;
}

/* Poop falls from initial top position (set in JS) to the bottom of the screen */
@keyframes poop-fall {
  0% {
    transform: translateY(0);
    /* Start from initial top position */
    opacity: 1;
  }

  100% {
    transform: translateY(300px);
    /* Fall to the bottom of the viewport */
    opacity: 0;
    display:none;
  }
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure header and footer stick */
  height: 100vh; /* Full viewport height */
  max-width: 100vw; /* Ensure it fits the screen width */
}

header, footer {
  flex-shrink: 0; /* Make sure header and footer don't shrink */
}

main {
  flex-grow: 1; /* Grow the main content to fill remaining space */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 100%; /* Keep the content within the width of the screen */
  box-sizing: border-box;
}

/* Wrapper that keeps the content centered with a max width */
.content-wrapper {
  width: 100%;
  max-width: 1200px; /* Set a max width for content */
  margin: 0 auto;
}

h1 {
  font-size: 6rem;
  margin: 0;
  padding: 10px;
  color: #ff5733;
  text-align: center;
}

/* Adjust the button */
.poop-button {
  display: inline-block;
  font-size: 1.5rem;
  padding: 15px 30px;
  margin-top: 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
}

.poop-button:hover {
  background-color: #cc5200;
}

/* Responsive breakpoints for smaller screens */
@media (max-width: 768px) {
  h1 {
    font-size: 3.5rem;
    padding: 5px;
  }

  .video-wrapper {
    max-width: 100%;
    padding-bottom: 56.25%;
  }

  .poop-button {
    font-size: 1.2rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2.5rem;
    padding: 5px;
  }

  .video-wrapper {
    max-width: 100%;
    padding-bottom: 56.25%; /* Keep the aspect ratio */
  }

  .poop-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

.poop-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
}