/* src/components/Footer.css */
footer {
    background-color: #ffcc99;
    color: #3e3e3e;
    padding: 20px;
    text-align: center;
    font-size: 1rem;
}
footer {
    flex-shrink: 0;
}